import * as React from "react"
import Layout from "../components/layout";
import * as Container from "../components/container/container";
import { StaticImage } from "gatsby-plugin-image";

const IndexPage = (props) => {
    return (
        <Layout pageTitle="OdD" props={props}>
            <main id="main">
                <Container.HeaderPart background="white no-image">
                    <div className="headline">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <h1>Eine Wanderausstellung erzählt Demokratiegeschichte</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container.HeaderPart>
                <Container.Part background="white">
                    <Container.Row>
                        <div className="col-6">
                            <p>
                                Ein Teil des Projektes „Orte der Demokratie in Bayern“ ist die Wanderausstellung, in der
                                die Demokratiegeschichte Bayerns dargestellt wird. Die Ausstellung lädt ein zur
                                gedanklichen Reise an die historischen Schauplätze, fragt aber auch nach der heutigen
                                Bedeutung der Orte.
                            </p>
                            <p>
                                Sie verfolgt dabei einen neuartigen Ansatz, der zeitlich und räumlich weit
                                auseinanderliegende Ereignisse zu einer Erzählung verbindet.
                            </p>
                            <p>
                                Anhand von historischen Exponaten werden zentrale Momente, Personen und Ereignisse der
                                Demokratiegeschichte Bayerns greifbar.
                            </p>
                            <p>
                                Mit dem Element der Graphic Novel werden gestützt auf Zeitzeugenberichten, Erinnerungen
                                oder Dokumenten auch persönliche Demokratiegeschichten einzelner Bürgerinnen und Bürger
                                dargestellt.
                            </p>
                            <p>
                                Um die Vielfalt der bayerischen Demokratiegeschichte hervorzuheben, werden klassische
                                Ausstellungsmethoden um interaktive Medienangebote ergänzt.
                            </p>
                            <p>
                                Gleichbleibende Basiselemente erlauben einen fundierten Blick auf alle für das Projekt
                                relevanten Orte; Erweiterungselemente stellen an ausgewählten Demokratieorten zusätzlich
                                vertiefte Informationen bereit und rücken thematische Schwerpunkte in den Vordergrund.
                            </p>
                            <p>
                                Das Ausstellungskonzept ist variabel gestaltet, um die Möglichkeit zu bieten, jederzeit
                                zusätzliche Stationen hinzuzufügen.
                            </p>
                            <p>
                                Das grafische Designe der Ausstellung soll vermitteln, dass alle Orte Teil einer
                                Demokratieentwicklung in Bayern sind.
                            </p>
                            <p>
                                Hochwertige Tafeln und Stellwände, ergänzt durch kleinere Sitz- und Ausstellungsmöbel,
                                ermöglichen es, sich flexibel an die unterschiedlichen örtlichen Gegebenheiten
                                anzupassen.
                            </p>
                        </div>
                        <div className="col-6">
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/ausstellung/PRP_0202.JPG"}
                                   data-glightbox="title: ; descPosition: bottom;">
                                    <StaticImage src="../../static/images/ausstellung/PRP_0202.JPG" width={350} layout="fixed" placeholder="none" alt=""/>
                                </a>
                            </figure>
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/ausstellung/PRP_0203.JPG"}
                                   data-glightbox="title: ; descPosition: bottom;">
                                    <StaticImage src="../../static/images/ausstellung/PRP_0203.JPG" width={350} layout="fixed" placeholder="none" alt=""/>
                                </a>
                            </figure>
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/ausstellung/PRP_0206.JPG"}
                                   data-glightbox="title: ; descPosition: bottom;">
                                    <StaticImage src="../../static/images/ausstellung/PRP_0206.JPG" width={350} layout="fixed" placeholder="none" alt=""/>
                                </a>
                            </figure>
                        </div>
                    </Container.Row>
                </Container.Part>
                <Container.Part background="beige">
                    <Container.Row>
                        <div className="col-12">
                            <h2>Inhalte und Gestaltung der Ausstellung</h2>
                        </div>
                    </Container.Row>
                    <Container.Row>
                        <div className="col-6">
                            <p>
                                Die inhaltliche Erarbeitung der Ausstellung übernahm die Münchner
                                Literaturwissenschaftlerin Laura
                                Mokrohs. Sie ist wissenschaftliche Mitarbeiterin und Doktorandin am Institut für
                                Deutsche Philologie der LMU München sowie freie Ausstellungskuratorin u.a. für das
                                Literaturhaus München und die Monacensia. Ihre Recherchen, Texte, Foto- und
                                Abbildungsauswahl
                                erfolgten in Abstimmung mit dem wissenschaftlichen Beirat.
                            </p>
                            <p>
                                Für die Zeichnungen der Graphic Novels konnte die Grafikerin Ingrid Sabisch aus Nürnberg
                                gewonnen werden, die u.a. mit Zeichnungen zu Publikationen über Sophie Scholl und Willy
                                Brandt Bekanntheit erlangte.
                            </p>
                        </div>
                        <div className="col-6">
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/ausstellung/PRP_0211.JPG"}
                                   data-glightbox="title: ; descPosition: bottom;">
                                    <StaticImage src="../../static/images/ausstellung/PRP_0211.JPG" width={350} layout="fixed" placeholder="none" alt=""/>
                                </a>
                            </figure>
                        </div>
                    </Container.Row>
                    <Container.Row>
                        <div className="col-6">
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/ausstellung/PRP_0221.JPG"}
                                   data-glightbox="title: ; descPosition: bottom;">
                                    <StaticImage src="../../static/images/ausstellung/PRP_0221.JPG" width={350} layout="fixed" placeholder="none" alt=""/>
                                </a>
                            </figure>
                            <figure className="lightbox">
                                <a className="glightbox" data-gallery="gallery1"
                                   href={"/images/ausstellung/PRP_0227.JPG"}
                                   data-glightbox="title: ; descPosition: bottom;">
                                    <StaticImage src="../../static/images/ausstellung/PRP_0227.JPG" width={350} layout="fixed" placeholder="none" alt=""/>
                                </a>
                            </figure>
                        </div>
                        <div className="col-6">
                            <p>
                                Das Gestaltungskonzept wurde vom Büro für Gestaltung Wangler und Abele aus München
                                entworfen.
                            </p>
                            <p>
                                Die helle Ausstellungsarchitektur mit großzügig dimensionierten modern kubischen
                                Elementen
                                ist in blau-weiß gehalten. Das Layout im visuellen Erscheinungsbild des Bayerischen
                                Landtags
                                führt mit klaren Orientierungspunkten durch die Demokratiegeschichte Bayerns und bringt
                                die ausgewählten Orte den Besucherinnen und Besuchern näher.
                            </p>
                            <p>
                                Aufgeklappte großformatige Ausstellungselemente, Buchseiten gleich, erzählen mittels
                                detailreichen Graphic Novels lebensnahe und lebendige Geschichten der Zeit, wie sie sich
                                so oder ähnlich abgespielt haben könnten.
                            </p>
                            <p>
                                Die Hintergründe, Personen, Gegenstände, Ausstattungen darin wurden historisch
                                sorgfältig
                                recherchiert. Neben den zentralen Inhalten der Demokratiegeschichte erfährt der
                                Betrachter
                                in „Nebenszenen“ auf unterhaltsame Art und Weise zugleich Wissenswertes und
                                zeitgeschichtlich Erhellendes aus den Blickwinkeln aller Bevölkerungsschichten.
                            </p>
                            <p>
                                Historische Dokumente werden mit Faksimile präsentiert, in denen mittels QR-Codes vor
                                Ort
                                gestöbert und nachgelesen werden kann. Historisches Kunst- und Bildmaterial
                                vervollständigt
                                den Blick auf das Geschehen.
                            </p>
                            <p>
                                Mittels interaktiven Touchsreens kann man sich zudem in individuell zu den Orten
                                erarbeitete
                                Themengebiete vertiefen.
                            </p>
                        </div>
                    </Container.Row>
                    <Container.Row>
                        <div className="col-12">
                            <div className="inner-container">
                                <h2>Zeichnerin der Graphic Novel.</h2>
                                <figure className="video">
                                    <div className="yt-container yt-enabled">
                                        <Container.Video
                                            url={"/videos/Filmmaterial/014_Zeichnerin/210705_Zeichnerin Graphic Novel ohne UT.mp4"}
                                            poster={"/images/Videowallpaper/zeichnerin.jpg"}/>
                                    </div>
                                </figure>
                            </div>
                        </div>
                    </Container.Row>
                </Container.Part>
            </main>
        </Layout>
    )
}

export default IndexPage
